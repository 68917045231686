import React from "react";
import CTAButton from "../components/CTAButton";

export default function Menu() {
  return (
    <nav className="max-w-screen-2xl w-full flex justify-between py-8 px-4 sm:px-8">
      <span>Théo Robert <br />— Motion Designer </span>
      <CTAButton />
    </nav>
  );
}
