import React from "react";

export default function Title() {
  return (
    <div className="px-4 sm:px-8">
      <h1 className="max-w-2xl text-xl sm:text-3xl py-16 text-center font-[Manrope]">
    Motion designer spécialisé dans la production de vidéos de présentation
  </h1>
      </div>
  );
}
