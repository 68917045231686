import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="border-t border-[rgba(0,0,0,0.12)] mt-8 max-w-screen-2xl w-full pt-16 sm:pt-32 pb-16 px-4 sm:px-8">
      <div className="mx-auto px-4 flex flex-col sm:flex-row justify-between items-left sm:items-center text-base gap-4 sm:gap-0">
        <div className="flex flex-col">
          <Link to="mailto:contact@theorobert.com">contact@theorobert.com</Link>
          <p>Paris, FRANCE</p>
        </div>
        <div className="flex flex-col text-left decoration-solid">
          <Link style={{
          textDecoration: 'underline',
        }} to="https://www.linkedin.com/in/th%C3%A9o-robert/">
            LinkedIn
          </Link>
          <Link style={{
          textDecoration: 'underline',
        }} className="hover:decoration-solid" to="https://vimeo.com/theorobert">Vimeo</Link>
        </div>
        <div className="text-left">
          <p>Copyright © 2024 Théo Robert.</p>
          <p>Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
}
