import React from "react";
import Menu from "../features/Menu";
import Title from "../features/Title";
import Projects from "../features/Projects";
import Clients from "../features/Clients";
import Projects2 from "../features/Projects2";
import Testimonials from "../features/Testimonials";
import Footer from "../features/Footer";

export default function Home() {
  return (
    <div className="flex flex-col items-center max-w-screen overflow-hidden">
      <Menu />
      <Title />
      <Projects />
      <Clients />
      <Projects2 />
      <Testimonials />
      <Footer />
    </div>
  );
}
