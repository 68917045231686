import React from "react";
import Project from "../components/Project";
import FinaryThumbnail from "../assets/images/FinaryThumbnail.jpg";
import BrandAppart from "../assets/images/BrandAppart.jpg";
import StakeThumbnail from "../assets/images/StakeThumbnail.jpg";
import Silex from "../assets/images/Silex.jpg";
import LogoLake from "../assets/images/LogoLakeBlanc.svg";
import LogoGenario from "../assets/images/LogoGenarioBlanc.png"; 
import LogoPoppins from "../assets/images/LogoPoppinsBlanc.svg";
import LogoMySpark from "../assets/images/LogoMySparkBlanc.svg";
import LogoCreme from "../assets/images/LogoCremeDeLaCremeBlanc.svg";
import LogoStake from "../assets/images/LogoStakeDAOBlanc.svg";
import Poppins from "../assets/images/Poppins.png";
import Creme from "../assets/images/Creme.png";
import Lak3 from "../assets/images/lak3.png";
import GenarioThumbnail from "../assets/images/GenarioThumbnail.jpg"

export default function Projects2() {
  return (
    <div className="flex flex-col gap-8 max-w-screen-2xl w-full px-6">
      {/* Programme Poppins */}
      <div className="flex justify-between gap-8 w-full lg:flex-row flex-col items-center">
        <Project
          grow={1}
          title="Programme Poppins"
          type="Vidéo de présentation"
          background={Poppins}
          logo={LogoPoppins}
          gradient={"rgba(155, 184, 255, 1)"}
          video={"https://player.vimeo.com/video/1012062104?h=036c295996"}
        />
        {/* Académie: Qu'est-ce qu'un smart contract ? */}
        <Project
          grow={1}
          title="Académie: Qu'est ce qu'un smart contract ?"
          type="Projet personnel"
          background={StakeThumbnail}
          logo={LogoStake}
          gradient={"rgba(0, 0, 0, 1)"}
          video={"https://player.vimeo.com/video/749259183?h=c365bfae71"}
        />
      </div>
      {/* Lak3 */}
      <div className="flex justify-between gap-8 w-full lg:flex-row flex-col items-center">
        <Project
          grow={1}
          title="Genario"
          type="Vidéo de présentation"
          background={GenarioThumbnail}
          gradient={"rgba(246, 213, 94, 1)"}
          logo={LogoGenario}
          video={"https://player.vimeo.com/video/749259791?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
        />
        {/* Silex - Produit mySpark */}
        <Project
          grow={1}
          title="Silex - Produit mySpark"
          type="Vidéo de présentation"
          background={Silex}
          logo={LogoMySpark}
          gradient={"rgba(35, 39, 45, 1)"}
          video={"https://player.vimeo.com/video/808026095?h=9705fc53a2"}
        />
        {/* Carbon Tracker */}
        <Project
          grow={1}
          title="Carbon Tracker"
          type="Vidéo de présentation"
          background={Creme}
          logo={LogoCreme}
          gradient={"rgba(37, 16, 96, 1)"}
          video={"https://player.vimeo.com/video/900882220?h=9ec3aaef6b"}
        />
      </div>
    </div>
  );
}
