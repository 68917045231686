import React from "react";
import LogoGrid from "../assets/images/LogosGrid.png";
import { Link } from "react-router-dom";

export default function Clients() {
  return (
    <div className="flex gap-4 sm:gap-16 py-16 sm:py-32 max-w-screen-2xl w-full overflow-auto flex-col md:flex-row px-6">
      <img
        src={LogoGrid}
        alt="logos de mes clients"
        className="md:w-2/3 w-full"
      />
      <div className="flex flex-col justify-between pt-4 w-full">
        <div className="max-h-[400px] h-full pb-8 border-b border-[rgba(0,0,0,0.12)] w-full">
          <p className="text-left">
            Après 4 années d'expérience auprès d'agences et de marques
            internationales, je collabore désormais principalement avec des
            sociétés dans le secteur tech.
            <br />
            <br />
            Ayant moi même co-créé un logiciel,{" "}
            <Link
              to={"https://lidap.io"}
              target="_blank"
              className="text-black font-bold underline"
            >
              lidap.io
            </Link>
            , j’aime dire que je suis "skin in the game" avec une compréhension
            profonde de vos enjeux d’acquisition et de communication.
          </p>
        </div>
        {/* <Link to="/" className="underline md:text-left text-center pt-4">
          En découvrir plus sur moi
        </Link> */}
      </div>
    </div>
  );
}
