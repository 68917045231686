import React from "react";
import PropTypes from "prop-types";
import Croix from "../assets/images/Croix.svg";

export default function Project({
  grow = 1,
  background,
  title,
  type,
  gradient = "rgba(0,0,0,.2)",
  logo,
  video,
}) {
  const [isPopUpActive, setIsPopUpActive] = React.useState(false);

  return (
    <>
      {isPopUpActive && (
        <div 
        className="fixed z-20 top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.75)] flex items-center justify-center backdrop-blur-lg" 
        onClick={() => setIsPopUpActive(false)}
        >
          <div className="max-w-screen-2xl relative w-full h-full max-h-[720px] flex items-center justify-center px-4 sm:px-16 py-16" 
        onClick={() => setIsPopUpActive(false)}>
            <div className="absolute top-0 right-0 cursor-pointer mt-8 mr-8">
              <img
              src={Croix}
              alt="close pop-up"
              
            />
              </div>
            <iframe
              title="vimeo-player"
              src={video}
              className="w-full h-full max-w-7xl max-h-[720px] cursor-pointer"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
      <div
        className={`flex rounded-2xl h-[330px] flex-grow-[${grow}] w-full overflow-hidden cursor-pointer hover:scale-105 transition-transform duration-300`}
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 50%, ${gradient} 100%), url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={() => setIsPopUpActive(true)}
      >
        <div className="flex items-center mt-auto px-4 sm:px-6 py-4 text-white bg-[rgba(0,0,0,.2)] w-full flex justify-between bg-blend-luminosity backdrop-blur-md">
          <div className="flex flex-col">
            <span className="text-lg font-semibold">{title}</span>
            <span className="text-xs text-neutral-200">{type}</span>
          </div>
          <img className="h-full w-full max-h-6 max-w-32 object-contain" src={logo} alt={`logo de ${title}`} />
        </div>
      </div>
    </>
  );
}

Project.propTypes = {
  grow: PropTypes.number,
  background: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  gradient: PropTypes.string,
  logo: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
};
