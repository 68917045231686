import React from "react";
import Testimonial from "../components/Testimonial";
import LogoSinvestir from "../assets/images/LogoSinvestirNoir.svg";
import LogoPinAds from "../assets/images/LogoPinAdsNoir.svg";
import LogoLeaderSante from "../assets/images/Logo_LeaderSante_Noir.png";
import LogoMySpark from "../assets/images/LogomySPARKNoir.svg";
import LogoCNRS from "../assets/images/LogoCNRSNoir.svg";
import LogoPodspace from "../assets/images/LogoPodspaceNoir.svg";
import LucBermond from "../assets/images/LucBermond.jpg";
import MatthieuLouvet from "../assets/images/MathieuLouvet.jpg";
import AnissataBoina from "../assets/images/AnnissataBoina.jpg";
import AnnaCecilia from "../assets/images/Anna-Cecilia.jpg";
import EmilieRudolf from "../assets/images/EmilieRudolf.jpg";
import JamesAlley from "../assets/images/JamesAlley.svg";

export default function Testimonials() {
  return (
    <div className="max-w-screen-2xl w-full flex flex-col px-4 sm:px-8 py-16 sm:py-32 text-center gap-12">
      <h1 className="font-bold text-2xl font-[Manrope]">Témoignages</h1>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-4 w-full flex-col lg:flex-row ">
          <Testimonial
            testimonial={`J'ai fait appel à Théo pour la réalisation de vidéos promotionnelles, et je suis extrêmement satisfait de son travail. Il a su s'adapter avec finesse à ma charte graphique, à mon secteur d'activité, tout en capturant parfaitement le message que je souhaitais transmettre. Théo est non seulement un monteur et motion designer talentueux, mais également un professionnel rigoureux et très agréable. \n \nC'est un véritable partenaire de confiance, et je le recommande vivement pour toute collaboration créative. Un grand bravo pour cette prestation !`}
            avatar={MatthieuLouvet}
            name="Matthieu Louvet"
            job="CEO S'investir"
            logo={LogoSinvestir}
          />
          <Testimonial
            testimonial={`On a eu le plaisir de collaborer avec Théo pour produire une vidéo de présentation de notre agence. Le rendu est superbe et correspond parfaitement à ce que nous souhaitions ! \n \nThéo est à l'écoute, flexible et agréable avec un grand souci du détail. Je recommande les yeux fermés.`}
            avatar={LucBermond}
            name="Luc Bermond"
            job="CEO Pin Ads"
            logo={LogoPinAds}
          />
          <Testimonial
            testimonial={`Nous avons sollicité Théo pour un besoin urgent de réalisation de deux vidéos de lancement de site internet. Théo a su faire preuve de créativité, force de proposition et d'un accompagnement sans faille pour avoir des vidéos qui répondent complètement à notre besoin. De plus, les timings étaient très short et il a réalisé les vidéos en un temps record tout en nous garantissant une qualité incroyable. Je recommande vivement Théo et je suis convaincue que c'est le début d'une très belle collaboration.`}
            avatar={AnissataBoina}
            name="Anissata Boina"
            job="Responsable digital"
            logo={LogoLeaderSante}
          />
        </div>
        <div className="flex justify-between gap-4 w-full  flex-col lg:flex-row ">
          <Testimonial
            testimonial={`Théo fait preuve d'un grand professionnalisme. Je suis très satisfaite de notre collaboration et n'hésiterai pas à faire appel à ses services à nouveau.`}
            avatar={AnnaCecilia}
            name="Anna-Cécilia"
            job="Product Owner"
            logo={LogoMySpark}
          />
          <Testimonial
            testimonial={`Une grande disponibilité et écoute, un souci du détail, un contact agréable, pour un résultat de qualité ! Théo nous a été recommandé par des collègues et nous le recommandons à notre tour avec plaisir ! Merci pour cette belle collaboration et réalisation. L'équipe OneWater`}
            avatar={EmilieRudolf}
            name="Emilie Rudolf"
            job="Communication"
            logo={LogoCNRS}
          />
          <Testimonial
            testimonial={`Théo maîtrise parfaitement son domaine, Il nous a livré un travail de qualité !`}
            avatar={JamesAlley}
            name="James Alley"
            job="CEO Podspace"
            logo={LogoPodspace}
          />
        </div>
      </div>
    </div>
  );
}
