import React from "react";
import Project from "../components/Project";
import BrandAppart from "../assets/images/BrandAppart.jpg";
import StableThumbnail from "../assets/images/StableThumbnail.jpg";
import LogoBitPandaBlanc from "../assets/images/LogoBitpandaBlanc.svg";
import LogoGrandEcran from "../assets/images/Logo_Grand Ecran Cinémas_Blanc.png";
import LogoLunarBlanc from "../assets/images/LogoLunarBlanc.svg";
import LogoBrandAppart from "../assets/images/LogoBrandAppartBlanc.svg";
import LogoStableGains from "../assets/images/LogoStableGainsBlanc.svg";
import Antoine from "../assets/images/bitPandaxAntoine.png";
import Lunar from "../assets/images/Lunar.png";
import GrandEcran from "../assets/images/grandEcran.png";
import LogoFinaryBlanc from "../assets/images/LogoFinaryBlanc.svg";
import LogoMeriaBlanc from "../assets/images/LogoMeriaBlanc.svg";
import LogoPinAdsBlanc from "../assets/images/Logo_Pin Ads_Blanc.png";
import Meria from "../assets/images/Meria.png";
import FinaryThumbnail from "../assets/images/FinaryThumbnail.jpg";
import GateOfMotion from "../assets/images/GateOfMotion.jpg";
import PinAds from "../assets/images/PinAds.jpg";
import LogoGateOfMotionBlanc from "../assets/images/Logo_GateOfMotion_Blanc.svg";



export default function Projects() {
  return (
    <div className="flex flex-col gap-8 max-w-screen-2xl w-full px-6">
      {/* Bottom Row */}
      <div className="flex justify-between gap-8 w-full lg:flex-row flex-col items-center">
        {/* Vidéo de présentation (Finary) */}
        <Project
          grow={1}
          title="Vidéo de présentation"
          type="Projet personnel"
          background={FinaryThumbnail}
          gradient={"rgba(165, 120, 85, 1)"}
          logo={LogoFinaryBlanc}
          video={"https://player.vimeo.com/video/749257095?h=ab73ee305f"}
        />
        {/* Application Meria */}
        <Project
          grow={1}
          title="Présentation Gate Of Motion"
          type="Vidéo de présentation"
          background={GateOfMotion}
          gradient={"rgba(5, 8, 39, 1)"}
          logo={LogoGateOfMotionBlanc}
          video={"https://player.vimeo.com/video/749244924?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
        />
      </div>
      <div className="flex justify-between gap-8 w-full lg:flex-row flex-col items-center">
        {/* Showreel 2024 */}
        <Project
          grow={1}
          title="Showreel 2024"
          type="Showreel"
          logo={LogoBrandAppart}
          background={BrandAppart}
          gradient={"rgba(3, 21, 28, 1)"}
          video={"https://player.vimeo.com/video/1012761982?h=b2c2085933"}
        />
        {/* Publicité StableGains */}
        <Project
          grow={1}
          title="Publicité StableGains"
          type="Vidéo ADS"
          logo={LogoStableGains}
          background={StableThumbnail}
          gradient={"rgba(73, 74, 249, 1)"}
          video={"https://player.vimeo.com/video/749262119?h=cf895d1557"}
        />
        {/* Bitpanda x Antoine */}
        <Project
          grow={1}
          title="Rebranding Pin Ads"
          type="Vidéo de lancement"
          logo={LogoPinAdsBlanc}
          background={PinAds}
          gradient={"rgba(230, 79, 70, 1)"}
          video={"https://player.vimeo.com/video/824712674?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
        />
      </div>

      {/* Middle Row */}
      <div className="flex justify-between gap-8 w-full lg:flex-row flex-col items-center">
        {/* Trailer Cinémas Grand Ecran */}
        <Project
          grow={2}
          title="Trailer Cinémas Grand Ecran"
          type="Générique de cinéma"
          background={GrandEcran}
          logo={LogoGrandEcran}
          video={"https://player.vimeo.com/video/1012048028?h=f393406b63"}
        />
        {/* Lunar Crystals */}
        <Project
          grow={1}
          title="Lunar Crystals"
          type="Vidéo explicative"
          background={Lunar}
          logo={LogoLunarBlanc}
          gradient={"rgba(37, 16, 96, 1)"}
          video={"https://player.vimeo.com/video/749906479?h=9245748cfa"}
        />
      </div>
    </div>
  );
}
