import React from "react";
import { Link } from "react-router-dom";

export default function CTAButton() {
  return (
    <Link
      to="https://cal.com/theorobert/30min?date=2024-10-03"
      className="bg-black text-white h-max px-4 py-2 text-base rounded-md"
    >
      Travailler ensemble
    </Link>
  );
}
