export default function Testimonial({ testimonial, avatar, name, job, logo }) {
  return (
    <div className="p-6 grow-1 w-full flex flex-col justify-between bg-[#FAFAFA] rounded-2xl border border-[#F1F1F1]">
      <p className="text-left">
        {testimonial.split("\n").map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p>
      <div className="flex items-center mt-4">
        <div className="flex items-center pr-8 border-r border-[#A7A7A7]">
          <img
            src={avatar}
            alt={`${name} avatar`}
            className="w-12 h-12 rounded-full"
          />
          <div className="flex flex-col items-left ml-4">
            <span className="block font-bold text-sm mr-auto">{name}</span>
            <span className="text-gray-500 text-xs mr-auto">{job}</span>
          </div>
        </div>
        <div className="ml-auto">
          <img
            src={logo}
            alt={`${name} company logo`}
            className="h-9 w-24 object-contain"
          />
        </div>
      </div>
    </div>
  );
}
